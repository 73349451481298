.App {
  font-family: serif;
  text-align: center;
  font-size: 8vw;
}

.bigCenter {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 100vh;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 25vw;
  color: gray;
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

body {
  background-image: url("https://source.unsplash.com/random/1024x1024");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

